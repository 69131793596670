import React from "react";
import s from '../css/timetable.module.css';
import doc from '../img/heart-doc.svg';
import phone from '../img/phone-pic.svg';

const Timetable = () => {
    return (
        <div>
            <div className={s.timetable__container} id='timetable'>
                <div className={s.timetable__header}>Программа<br/>курса<br/>вебинара</div>
                <div className={s.timetable__content}>

                    <div className={s.timetable__card}>
                        <div className={s.date}>
                            <img src={doc} alt={""}/>
                        </div>
                        <h3>Маркетинг для медицинских клиник. Как найти клиентов в новых реалиях?</h3>
                        <button className={s.timetable__blueButton} sp-show-form="200388">Записаться</button>
                    </div>

                    <div className={s.timetable__card}>
                        <div className={s.date}>
                            <img src={phone} alt={""}/>
                            <h3></h3>
                        </div>
                        <h3>Врач-блогер. Что нужно знать, чтобы успешно вести социальные сети и продавать свои услуги</h3>
                        <button className={s.timetable__blueButton} sp-show-form="200190">Смотреть запись</button>
                    </div>
                    <div className={s.timetable__card}>
                        <div className={s.date}>
                            <img src={phone} alt={""}/>
                        </div>
                        <h3>Маркетинг для медицинских клиник. Аналитика. Что и как считать?</h3>
                        <button className={s.timetable__blueButton} sp-show-form="200189">Смотреть запись</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Timetable;