import Navbar from "./components/Navbar";
import '../src/css/App.css';
import Main from "./components/Main";
import Leading from "./components/Leading";
import ForWho from "./components/ForWho";
import Timetable from "./components/Timetable";
import Checklist from "./components/Checklist";
import YararMed from "./components/YararMed";
import Projects from "./components/Projects";
import FormPage from "./components/FormPage";
import Footer from "./components/Footer";

function App() {
    return (
        <>
            <div className="navbar__background">
                {/*<div className='wrapper'>*/}
            <Navbar/>
            {/*</div>*/}
            </div>
            <div className="main__background">
                <div className="wrapper">
                    <Main/>
                </div>
            </div>
            <div className="leading__background">
                <div className="wrapper">
                    <Leading/>
                </div>
            </div>
            <div className="wrapper">
                <ForWho/>
            </div>
            <div className="wrapper">
                <Timetable/>
            </div>
            <div className="checklist__background">
                <div className="wrapper">
                    <Checklist/>
                </div>
            </div>
            <div className="yararMed__background">
                <div className="wrapper">
                    <YararMed/>
                </div>
            </div>
            <div className="wrapper">
                <Projects/>
            </div>
            <div className="formPage__background">
                <div className="wrapper">
                    <FormPage/>
                </div>
            </div>
            <div className="footer__background">
                <div className="wrapper">
                    <Footer/>
                </div>
            </div>
            <div className="under-footer">
                <div className="wrapper">
                    <div className="under-footer__container">
                        <p>© 2021, ООО "МА ЯРАР"</p>
                        <p>Политика конфиденциальности</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
