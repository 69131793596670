import React from "react";
import s from '../css/forWho.module.css';
import man from '../img/man-icon.svg';
import woman from '../img/woman-icon.svg';
import doctor from '../img/doctor.svg';
const ForWho = () => {
    return(
        <div className={s.forWho__container}>
            <div className={s.forWho__header}>
                Для кого будет полезен вебинар
            </div>
            <div className={s.forWho__content_container}>
                <div className={s.forWho__card}>
                    <img src={man} alt={""}/>
                    <p>Владельцы, директора, управляющие частных клиник и медицинских центров</p>
                </div>
                <div className={s.forWho__card}>
                    <img src={woman} alt={""}/>
                    <p>Маркетологи и администраторы, которые работают внутри медицинских организаций или со стороны агентства</p>
                </div>
                <div className={s.forWho__card}>
                    <img src={doctor} alt={""}/>
                    <p>Врачи, для которых важно развитие своего личного бренда и увеличение прибыли</p>
                </div>
            </div>
        </div>
    )
}

export default ForWho;