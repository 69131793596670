import React from "react";
import s from '../css/formPage.module.css';
import '../css/formPage.module.css';
const FormPage = () => {
    return (
        <div className={s.formPage__container}>
            <div className={s.formPage__text_block}>
                <div className={s.formPage__text_header}>Оставить заявку!</div>
                <div className={s.formPage__text}>Заполните форму,
                    чтобы записаться на цикл бесплатных вебинаров «Маркетинг для медицинских клиник»
                </div>
                <button sp-show-form="200189">Оставить заявку</button>
            </div>

            <div className={s.formPage__form_block}>

            </div>
        </div>

    )
}

export default FormPage;