import React from "react";
import s from '../css/yararMed.module.css';
import chat from '../img/chat-icon.png';
import ads from '../img/ads-icon.png';
import social from '../img/social-icon.png';
import hand from '../img/hand-icon.png';
import help from '../img/site-help-icon.png';
import lupa from '../img/lupa-icon.png';
import hospital from '../img/site-kliniki.png';
import room from '../img/room.png';

const YararMed = () => {
    return (
        <div className={s.yararMed__container} id='yararMed'>
            <div className={s.yararMed__head_text}>
                <h2>Yarar Med – Маркетинг, IT и консалтинг для клиник</h2>
                <p>Мы знаем инструменты, которые помогут клиенту найти вас. Мы больше 6 лет работаем с медицинскими
                    организациями различного профиля и отладили решения для успешной работы клиники.</p>
            </div>
            {/*first row*/}
            <div className={s.yararMed__content_row}>
                <div className={s.yararMed__content_card}>
                    <img src={chat} alt={''}/>
                    <h4>Аналитика и повышение качества</h4>
                    <p>Внедряем CRM, выстраиваем сквозную аналитику, улучшаем воронку продаж на каждом этапе.
                        Прослушиваем звонки. Проводим анализ и помогаем улучшить качество сервиса и маркетинга. </p>
                </div>
                <div className={s.yararMed__content_card}>
                    <img src={ads} alt={''}/>
                    <h4>Контекстная реклама</h4>
                    <p>Самый быстрый способ привлечь клиентов. Настраиваем рекламу по конкретным услугам, на показ в
                        ближайшем радиусе с вашим заведением. Создаём рекламные баннеры и настраивает ремаркетинг. </p>
                </div>
                <div className={s.yararMed__content_card}>
                    <img src={social} alt={''}/>
                    <h4>Реклама в социальных сетях</h4>
                    <p>В соцсетях хорошо выстраивать доверительные долгосрочные отношения со своими клиентами. Создадим
                        и подготовим аккаунты, напишем стратегию, контент-план, подготовим промо и запустим
                        рекламу. </p>
                </div>
            </div>
            {/*second row*/}
            <div className={s.yararMed__content_row}>
                <div className={s.yararMed__content_card}>
                    <img src={hand} alt={''}/>
                    <h4>Работа с отзывами и агрегаторами</h4>
                    <p>SERM – создание позитивного образа через работу с отзывами. Работаем со справочными сервисами и
                        агрегаторами (ПроДокторов, Docdoc). Улучшаем репутацию. Налаживаем клиентский трафик. </p>
                </div>
                <div className={s.yararMed__content_card}>
                    <img src={help} alt={''}/>
                    <h4>Поддержка сайта</h4>
                    <p>Поддержание сайта в боевом состоянии. Оперативное реагирование на все вопросы. Регулярное
                        обновление сайта и новостной ленты. </p>
                </div>
                <div className={s.yararMed__content_card}>
                    <img src={lupa} alt={''}/>
                    <h4>Поисковое продвижение</h4>
                    <p>SEO - долгосрочные результаты и высокая рентабельность. Подберем все запросы, связанные с вашей
                        деятельностью и сделаем так, чтобы ваши клиенты по ним вас находили. </p>
                </div>
            </div>
            {/*third row*/}
            <div className={s.yararMed__content_row}>
                <div className={s.yararMed__content_width_card}>
                    <img src={hospital} alt={''}/>
                    <h4>Продающий сайт клиники</h4>
                    <p>Все предыдущие пункты комплексного продвижения работают только при наличии правильно
                        подготовленного сайта. Раскрыта ли вся необходимая информация для клиента? Расставлены триггеры
                        доверия? Удобно ли клиенту сделать заказ или позвонить с сайта? Есть ли мобильная версия?
                        Всё это очень сильно влияет на эффективность рекламы, поэтому продвижение необходимо начинать с
                        работ по сайту.
                        В случае необходимости оперативно создадим сайт, который будет отлично продавать медицинские и
                        стоматологические услуги вашей клиники. </p>
                </div>
                <img className={s.room_img} src={room} alt={''} loading='lazy'/>
            </div>
            <button className={s.yararMed__button} sp-show-form="200311">
                Оставить заявку
            </button>
        </div>

    )
}

export default YararMed;