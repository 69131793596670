import React from "react";
import s from '../css/footer.module.css';
import logo from '../img/yarar-logo-white.svg';
import vk from '../img/vk-white.svg';
import inst from '../img/inst-white.svg';
import fb from '../img/telegram.png';
const Footer = () => {
  return(
      <div className={s.footer__container} id='footer'>
        <img src={logo}  alt={''}/>
          <div className={s.footer__address}>
              <p>Казань, ул. Зинина д.9/23, оф. 2</p>
              <a href="mailto:hello@yarar.ru"><p>hello@yarar.ru</p></a>
          </div>
          <div className={s.footer__contacts}>
              <a href="tel:+79674637767"><p>+7 967 463-77-67</p></a>
              <div className={s.footer__contacts_icons}>
                  <a href="https://m.vk.com/ma_yarar" target="_blank" rel="noreferrer"><img src={vk} alt={''}/></a>
                  <a href="https://instagram.com/yararkzn?utm_medium=copy_link" target="_blank" rel="noreferrer"><img src={inst} alt={''}/></a>
                  <a href="https://t.me/ma_yarar_kzn" target="_blank" rel="noreferrer"><img src={fb} alt={''}/></a>
              </div>
          </div>
      </div>
  )
}

export default Footer;

