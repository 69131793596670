import React from "react";
import check from '../img/check-sign.svg';
import s from '../css/checklist.module.css';

const Checklist = () => {
    return (
        <div className={s.checklist__container} id='checklist'>
            <div className={s.checklist__header}>
                <img src={check} alt={''}/> Проверьте маркетинг своей клиники
            </div>
            <div className={s.checklist__text}>
                Мы подготовили чек-лист, по которому вы можете проверить маркетинг вашей клиники: всё ли вы
                предусмотрели и какие действия вы ещё можете сделать, чтобы увеличить поток клиентов и повысить качество
                услуг.
            </div>
            <button className={s.checklist__button} sp-show-form="200188">Заполнить чек-лист</button>
        </div>
    )
}

export default Checklist;