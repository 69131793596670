import React from "react";
import app from '../css/App.css';
import '../css/main.module.css';
import logo from '../img/yarar-logo.svg';
import phone from '../img/phone-pic.svg';
import s from '../css/main.module.css';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-scroll';

const Main = () => {
    return (
        <div className={app.wrapper}>
            <div className={s.main__content_container}>
                <div className={s.main__leftSide_content}>
                    <img src={logo} alt={logo} style={{width: 126, height: 60}}/>
                    <Link to="leading" smooth={true} className={s.menu}>Кто рассказывает</Link>
                    <Link to="timetable" smooth={true} className={s.menu}>Программа</Link>
                    <Link to="checklist" smooth={true} className={s.menu}>Чек-лист</Link>
                    <Link to="yararMed" smooth={true} className={s.menu}>Кто делает</Link>
                    <Link to="footer" smooth={true} className={s.menu}>Контакты</Link>
                </div>
                <div className={s.main__rightSide_content}>
                    <div className={s.main__header}>
                        Цикл бесплатных вебинаров
                    </div>
                    <div className={s.date}>
                        <p>Ближайший вебинар</p>
                        <p><span>9 марта, 13:00 МСК</span></p>
                    </div>
                    <div className={s.main__main_header}>
                        <h1>Маркетинг для медицинских клиник. Как найти клиентов в новых реалиях?</h1>
                        <img src={phone} alt={phone}/>
                    </div>
                    <div className={s.main__description}>
                        О том как в 2023 году привлекать клиентов в медицинские клиник. Какие маркетинговые инструменты работают и что делать обязательно.
                    </div>
                    <div className={s.main__checkpoints}>
                        <div className={s.main__checkpoint}>
                            <FontAwesomeIcon icon={faCheck}/>
                            <p> Какие рекламные каналы продолжают работать</p>
                        </div>
                        <div className={s.main__checkpoint}>
                            <FontAwesomeIcon icon={faCheck}/>
                            <p> Как выстроить систему работы со входящим потоком в клинике</p>
                        </div>
                        <div className={s.main__checkpoint}>
                            <FontAwesomeIcon icon={faCheck}/>
                            <p>Как контролировать работу маркетологов и какие задачи им ставить</p>
                        </div>
                    </div>
                    <button className={s.main__button} sp-show-form="200388">
                        Зарегистрироваться
                    </button>
                </div>
            </div>
        </div>
    )

}

export default Main;