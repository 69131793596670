import React, {useState} from "react";
import s from '../css/navbar.module.css'
import logo from '../img/yarar-logo.svg';
import {Link} from 'react-scroll';

const Navbar = () => {
    const [navbar, setNavbar] = useState(false);
    const scrolling = () => {
        if (window.scrollY >= 1000) {
            setNavbar(true);
        }
        if (window.scrollY < 1000) {
            setNavbar(false);
        }
    }
    window.addEventListener('scroll', scrolling)

    const [icon, setIcon] = useState(true);



    return (
        <>
            <nav className={s.navbar}>
                <div className={s.wrapper}>
                    <div className={navbar ? s.sticky : s.navbar_container}>
                        <img src={logo} alt={"logo"} id='logo' style={{width: '94.5px', height: '45px' }}/>
                        <div className={s.menu_container}>
                            <Link to="leading" smooth={true} className={s.menu}>Кто рассказывает</Link>
                            <Link to="timetable" smooth={true} className={s.menu}>Программа</Link>
                            <Link to="checklist" smooth={true} className={s.menu}>Чек-лист</Link>
                            <Link to="yararMed" smooth={true} className={s.menu}>Кто делает</Link>
                            <Link to="footer" smooth={true} className={s.menu}>Контакты</Link>
                        </div>
                        <div className={s.navbar_button} sp-show-form="200189">
                            <button>Зарегистрироваться</button>
                        </div>
                        <button className={icon ? s.navbar_hamburger : s.navbar_close}
                                onClick={() => setIcon(!icon)}>
                        </button>
                    </div>
                    <div className={icon ? s.burger_menu_container : s.burger_menu_active}>
                        <div className={s.burger_menu_text}>
                            <Link to="leading" smooth={true} className={s.menu}>Кто рассказывает</Link>
                            <Link to="timetable" smooth={true} className={s.menu}>Программа</Link>
                            <Link to="checklist" smooth={true} className={s.menu}>Чек-лист</Link>
                            <Link to="yararMed" smooth={true} className={s.menu}>Кто делает</Link>
                            <Link to="footer" smooth={true} className={s.menu}>Контакты</Link>
                        </div>
                        <div className={s.burger_button} sp-show-form="200189">
                            <button>Зарегистрироваться</button>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )

}

export default Navbar;