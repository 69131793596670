import React from "react";
import app from '../css/App.css'
import s from '../css/leading.module.css';
import vk from '../img/vk-icon.svg';
import inst from '../img/inst-icon.svg';
import ilya from '../img/ilya.png';
const Leading = () => {
    return (
        <div className={app.wrapper}>
            <div className={s.leading__container} id='leading'>
                <div className={s.leading__text_container}>
                    <div className={s.leading__header}>
                        Ведущий курса вебинаров
                    </div>
                    <div className={s.leading__name}>
                        <h2>Попов Илья </h2>
                        <div className={s.leading__icons}>
                            <a href="https://vk.com/popof" target="_blank" rel="noreferrer"><img src={vk} alt={""}/></a>
                            <a href="https://www.instagram.com/goodserfer/" target="_blank" rel="noreferrer"><img src={inst} alt={""}/></a>
                        </div>
                    </div>
                    <div className={s.leading__about}>
                        Руководитель агентства медицинского маркетинга Yarar
                    </div>
                    <div className={s.leading__description}>
                        Основатель и руководитель маркетингового агентства Yаrar. Кандидат наук и преподаватель
                        Казанского университета, Высшей Школы Экономики. Опыт в маркетинге – 11 лет. Лауреат
                        государственных премий. Основатель общественного движения «Гудсёрфинг» с более чем 70 тыс.
                        участников.
                    </div>
                </div>
                <div className={s.leading__person}>
                    <img src={ilya} alt={""} loading='lazy'/>
                </div>
            </div>
        </div>
    )
}

export default Leading;