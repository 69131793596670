import React from "react";
import s from '../css/projects.module.css'
import rasches from '../img/rascheskof.png';
import artus from '../img/artus.png';
import dynasty from '../img/dynastiya.png';
import neo from '../img/neo.png';
import optica from '../img/optika.png';
import trend from '../img/trend-optica.png';
import madin from '../img/madin.png';
import charm from '../img/charm.png';
const Projects = () => {
    return(
        <div className={s.projects__container}>
            <div className={s.projects__header}>
                Наши проекты и клиенты
            </div>
            <div className={s.projects__pics_container}>
                <div className={s.projects__company_pic}>
                    <img src={rasches} alt={''} loading='lazy'/>
                </div>
                <div className={s.projects__company_pic}>
                    <img src={artus} alt={''} loading='lazy'/>
                </div>
                <div className={s.projects__company_pic}>
                    <img src={dynasty} alt={''} loading='lazy'/>
                </div>
                <div className={s.projects__company_pic}>
                    <img src={neo} alt={''} loading='lazy'/>
                </div>
                <div className={s.projects__company_pic}>
                    <img src={optica} alt={''} loading='lazy'/>
                </div>
                <div className={s.projects__company_pic}>
                    <img src={trend} alt={''} loading='lazy'/>
                </div>
                <div className={s.projects__company_pic}>
                    <img src={madin} alt={''} loading='lazy'/>
                </div>
                <div className={s.projects__company_pic}>
                    <img src={charm} alt={''} loading='lazy'/>
                </div>
            </div>
        </div>
    )
}

export default Projects;